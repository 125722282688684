import { makeAutoObservable } from "mobx";

class AppStore {
  currency: string = "USD";
  symbol: string = "$";

  constructor() {
    makeAutoObservable(this);
  }

  setCurrency(val: string) {
    this.currency = val;
    this.symbol = val === "USD" ? "$" : "€";
  }
}

const appStore = new AppStore();
export default appStore;
