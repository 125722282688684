import { makeStyles } from "tss-react/mui";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import { createTheme, ThemeProvider } from "@mui/material";
import CoinPage from "./pages/CoinPage";

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    mode: "dark",
  },
});

const useStyles = makeStyles()(() => ({
  App: {
    backgroundColor: "var(--bg-dark)",
    color: "var(--font-light)",
    minHeight: "100vh",
  },
}));

function App() {
  const { classes } = useStyles();

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.App}>
        <Header />
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="/coins/:id" element={<CoinPage />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
